import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Overflow menu`}</em>{` is used when additional options are available to the user and there is a space constraint.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <p>{`The text within an overflow menu should be direct so users can quickly decide on an action. Actions that could cause a significant change to the user’s data (delete app, delete service, etc.) are separated by a horizontal rule and live below the primary set of actions.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABSElEQVQoz5WSPUvDYBDHD1u14KoIXTqIoEMdHBT8Yu6CLs5OGgUXFYOLoLPUNoIlpvUlNkKWlpbmpXlvmjTN+eRRHISS9uDguP/dj7vjAP5ZvV6HIAhorKoqGIYBlmWBaZrguu5fHSLCVBaG4VhtKpgkSRDHMY01TcuS5lzisixndF1fJrljjuNyiS4IQjowKfJ9P/O78l673VYIRHQc59O27RZZX2VZdj7Ra7VaOvCF58H3PApUut2TURShqig48H3EOEbbNFunDDNL7z0JUBBFIK0/Ezr2kRsNsdPr9c3BIPDiEdrDsHl+eUGBr+Q8qfZ8dQ0eL1Bg56HEYOMLlTKHWqmM/SqPeuWpd7Z/MJfoPHuTDrzLr0CzuD1DJ1jd2G1s7nyIxa3H98J6hXj1rbB2e7iYzyb6Pamd2NJeY+o/XEjRl8bkvwEkocsbtjy6hQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An overflow menu with a significant action",
            "title": "An overflow menu with a significant action",
            "src": "/static/4ebc03836fe0d6098f78a678e02b8774/fb070/overflow-menu-usage-1.png",
            "srcSet": ["/static/4ebc03836fe0d6098f78a678e02b8774/d6747/overflow-menu-usage-1.png 288w", "/static/4ebc03836fe0d6098f78a678e02b8774/09548/overflow-menu-usage-1.png 576w", "/static/4ebc03836fe0d6098f78a678e02b8774/fb070/overflow-menu-usage-1.png 1152w", "/static/4ebc03836fe0d6098f78a678e02b8774/c3e47/overflow-menu-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABSElEQVQoz5WSPUvDYBDHD1u14KoIXTqIoEMdHBT8Yu6CLs5OGgUXFYOLoLPUNoIlpvUlNkKWlpbmpXlvmjTN+eRRHISS9uDguP/dj7vjAP5ZvV6HIAhorKoqGIYBlmWBaZrguu5fHSLCVBaG4VhtKpgkSRDHMY01TcuS5lzisixndF1fJrljjuNyiS4IQjowKfJ9P/O78l673VYIRHQc59O27RZZX2VZdj7Ra7VaOvCF58H3PApUut2TURShqig48H3EOEbbNFunDDNL7z0JUBBFIK0/Ezr2kRsNsdPr9c3BIPDiEdrDsHl+eUGBr+Q8qfZ8dQ0eL1Bg56HEYOMLlTKHWqmM/SqPeuWpd7Z/MJfoPHuTDrzLr0CzuD1DJ1jd2G1s7nyIxa3H98J6hXj1rbB2e7iYzyb6Pamd2NJeY+o/XEjRl8bkvwEkocsbtjy6hQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An overflow menu with a significant action",
            "title": "An overflow menu with a significant action",
            "src": "/static/4ebc03836fe0d6098f78a678e02b8774/fb070/overflow-menu-usage-1.png",
            "srcSet": ["/static/4ebc03836fe0d6098f78a678e02b8774/d6747/overflow-menu-usage-1.png 288w", "/static/4ebc03836fe0d6098f78a678e02b8774/09548/overflow-menu-usage-1.png 576w", "/static/4ebc03836fe0d6098f78a678e02b8774/fb070/overflow-menu-usage-1.png 1152w", "/static/4ebc03836fe0d6098f78a678e02b8774/c3e47/overflow-menu-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "positioning"
    }}>{`Positioning`}</h4>
    <p>{`Depending on where the Overflow Menu appears within the UI, the caret or arrow may be left or right aligned so the Overflow Menu is clearly visible.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABCklEQVQoz52SSWrDUAyGRZM2uUHu4ct13zt05dQLr73rFUoNxuBhkRQvDMbDwkM9Uk/Yr1JosihJHEcgEPqlD+npAfwz0zShbdtDHEURpGkKeZ5DlmVQVdWpjjEGs6zruovaLJhlWTCO4yGO43iJzWty27YXSZJsMMfLsrwmXdf1aSAV1XW9+Fv5JQiCECH7siy/iqLwcP1IkqQV6YZhTAM1TTsBwzDcDsPAEMyapmFkBBUE4fH43nOBr3gIhlP+4HFainFCVxTFu4FvNBWuTCDW9z1N+I3Ap5uBiqIANj1Q7Pv+s+M4O8/zPvAgn+gqwt95nl+Srqrq7dee+hqz/6Hruld1juPO5n8BSbPfsDU9b6YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Overflow menu right aligned",
            "title": "Overflow menu right aligned",
            "src": "/static/0386ff9be42451b3209d5b2d05298e32/fb070/overflow-menu-usage-2.png",
            "srcSet": ["/static/0386ff9be42451b3209d5b2d05298e32/d6747/overflow-menu-usage-2.png 288w", "/static/0386ff9be42451b3209d5b2d05298e32/09548/overflow-menu-usage-2.png 576w", "/static/0386ff9be42451b3209d5b2d05298e32/fb070/overflow-menu-usage-2.png 1152w", "/static/0386ff9be42451b3209d5b2d05298e32/c3e47/overflow-menu-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAA70lEQVQoz62SuQrCUBBFnwuI/2Vtp4UQ/8LK3iKfYCf+ilgFG0HrELKQfZcsON4JsQwk4jQzk/veeXeYCNERRNRkRVFEmqYiiiIRx7EIw7D5XpalcBxH/CWyLBt2wff9cZuXQRDs2noOdzP0U+7Zteu6/YA4OOHsed7Wsqy3bduPJEmeGNkE9MBaVVWj3iPj4KQFrwAgZAKQONCfWKvreoyHBgPXcEmGYRSoX4AQRj62S/kJuCmKghgKUOMQ9Zk1OO4PhKNmKaZpLjRNu6mqegX8gsXc8dietTzPR7qu99/09z8cqnWGLMudmiRJndoHo1zOOJxqvNUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "overflow menu left aligned",
            "title": "overflow menu left aligned",
            "src": "/static/4393eb37f00a1eaff69d20925dcf627c/fb070/overflow-menu-usage-3.png",
            "srcSet": ["/static/4393eb37f00a1eaff69d20925dcf627c/d6747/overflow-menu-usage-3.png 288w", "/static/4393eb37f00a1eaff69d20925dcf627c/09548/overflow-menu-usage-3.png 576w", "/static/4393eb37f00a1eaff69d20925dcf627c/fb070/overflow-menu-usage-3.png 1152w", "/static/4393eb37f00a1eaff69d20925dcf627c/c3e47/overflow-menu-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      